<script>
import { FALLBACK_MESSAGE, isFalsyString } from '@emobg/web-utils';
import fleetRoutes from '@domains/Fleet/router/FleetRouterMap';
import { CARSHARING_PERMISSIONS } from '@domains/Carsharing/const/permissions';
import PermissionLink from '@/components/PermissionLink/PermissionLink';

export default {
  name: 'LocationTemplate',
  components: {
    PermissionLink,
  },
  props: {
    address: {
      type: Object,
      default: () => undefined,
    },
  },
  created() {
    this.FALLBACK_MESSAGE = FALLBACK_MESSAGE;
    this.CARSHARING_PERMISSIONS = CARSHARING_PERMISSIONS;
    this.fleetRoutes = fleetRoutes;
  },
  methods: {
    isFalsyString,
  },
};
</script>

<template>
  <ui-tooltip :tooltip="`${address.location}, ${address.city}`">
    <PermissionLink
      :link-permissions="[CARSHARING_PERMISSIONS.viewCarsharingLocations]"
      :to="{
        name: fleetRoutes.locations.detail.index,
        params: {
          locationUuid: address.location_uuid,
        },
      }"
      data-test-id="location-link"
      class="emobg-font-weight-semibold"
    >
      {{ isFalsyString(address.location) ? FALLBACK_MESSAGE.dash : address.location }}
      <br class="d-none d-md-block">
      {{ isFalsyString(address.city) ? FALLBACK_MESSAGE.dash : address.city }}
    </PermissionLink>
  </ui-tooltip>
</template>
