<script>
import { FALLBACK_MESSAGE, isFalsyString } from '@emobg/web-utils';

export default {
  name: 'PhoneTemplate',
  props: {
    phone: {
      type: String,
      default: () => undefined,
    },
  },
  created() {
    this.FALLBACK_MESSAGE = FALLBACK_MESSAGE;
  },
  methods: {
    isFalsyString,
  },
};
</script>

<template>
  <div>
    <a
      v-if="phone && !isFalsyString(phone)"
      :href="`tel:${phone}`"
      class="emobg-link-primary emobg-body-2"
      target="_blank"
    >
      {{ phone }}
    </a>
    <span v-else> {{ FALLBACK_MESSAGE.dash }} </span>
  </div>
</template>
