var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ui-button",
    {
      staticClass: "d-block",
      attrs: {
        color: _vm.GRAYSCALE.inkLight,
        face: _vm.FACES.text,
        size: _vm.SIZES.small,
        "data-test-id": "delete-button-template",
      },
      on: { clickbutton: _vm.onClick },
    },
    [
      _c("ui-icon", {
        staticClass: "mr-4",
        attrs: { icon: _vm.ICONS.trash, size: _vm.ICONS_SIZES.xLarge },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }