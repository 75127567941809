<script>
import { FALLBACK_MESSAGE } from '@emobg/web-utils';
export default {
  props: {
    alerts: {
      type: Array,
      default: () => [],
    },
  },
  created() {
    this.FALLBACK_MESSAGE = FALLBACK_MESSAGE;
  },
};
</script>

<template>
  <section v-if="alerts.length">
    <div
      v-for="(alert, key) in alerts"
      :key="key"
      :data-test-id="`alert-${key}`"
    >
      <div class="d-inline-block p-2 emobg-background-color-moderate-light pill emobg-color-black align-middle" />
      <span class="ml-1 align-middle">{{ alert }}</span>
    </div>
  </section>
  <div v-else>
    <div class="d-inline-block p-2 emobg-background-color-ground-light pill emobg-color-black align-middle" />
    <span class="emobg-color-ink-light">
      No alerts
    </span>
  </div>
</template>

