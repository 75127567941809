var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ui-tooltip",
    { attrs: { tooltip: `${_vm.address.location}, ${_vm.address.city}` } },
    [
      _c(
        "PermissionLink",
        {
          staticClass: "emobg-font-weight-semibold",
          attrs: {
            "link-permissions": [
              _vm.CARSHARING_PERMISSIONS.viewCarsharingLocations,
            ],
            to: {
              name: _vm.fleetRoutes.locations.detail.index,
              params: {
                locationUuid: _vm.address.location_uuid,
              },
            },
            "data-test-id": "location-link",
          },
        },
        [
          _vm._v(
            " " +
              _vm._s(
                _vm.isFalsyString(_vm.address.location)
                  ? _vm.FALLBACK_MESSAGE.dash
                  : _vm.address.location
              ) +
              " "
          ),
          _c("br", { staticClass: "d-none d-md-block" }),
          _vm._v(
            " " +
              _vm._s(
                _vm.isFalsyString(_vm.address.city)
                  ? _vm.FALLBACK_MESSAGE.dash
                  : _vm.address.city
              ) +
              " "
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }