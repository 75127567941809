<script>
import { FALLBACK_MESSAGE, isFalsyString } from '@emobg/web-utils';

export default {
  name: 'ListTemplate',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
  },
  created() {
    this.FALLBACK_MESSAGE = FALLBACK_MESSAGE;
  },
  methods: {
    isFalsyString,
  },
};
</script>

<template>
  <div class="d-flex flex-column mt-n2">
    <div class="emobg-font-weight-semibold pb-2">
      {{ title }}
    </div>
    <div
      v-if="items.length"
      class="d-flex flex-column"
    >
      <div
        v-for="item in items"
        :key="item"
        class="pb-1"
      >
        {{ item }}
      </div>
    </div>
    <div
      v-else
      class="d-flex flex-column"
    >
      <div>
        {{ FALLBACK_MESSAGE.dash }}
      </div>
    </div>
  </div>
</template>

