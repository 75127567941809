<script>
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { FALLBACK_MESSAGE, sentenceCase } from '@emobg/web-utils';

export default {
  name: 'LogEventTemplate',
  props: {
    section: {
      type: Object,
      default: undefined,
    },
    attributes: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
  },
  methods: {
    sentenceCase,
    getAttributeValue(section, attribute) {
      const value = get(section, attribute, FALLBACK_MESSAGE.dash);

      return isEmpty(value) ? FALLBACK_MESSAGE.dash : value;
    },
  },
};
</script>

<template>
  <div>
    <div
      v-for="attribute in attributes"
      :key="attribute"
      class="pb-4"
    >
      <p class="emobg-font-weight-bold pb-1">
        <strong>{{ sentenceCase(`${title} ${attribute}`) }}:</strong>
      </p>
      {{ getAttributeValue(section, attribute) }}
    </div>
  </div>
</template>
