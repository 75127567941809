import find from 'lodash/find';
import get from 'lodash/get';
import {
  DATE_FORMAT,
  FALLBACK_MESSAGE,
  formatUtc,
} from '@emobg/web-utils';

export const normalizeDate = value => (
  value
    ? formatUtc(value, DATE_FORMAT.dob)
    : FALLBACK_MESSAGE.notAvailable
);

export const getNameFromList = list => value => get(
  find(list, ['value', value]),
  'label',
);

export const formatUuid = (uuid = '') => uuid.substr(0, 7);
