<script>
import { sentenceCase } from '@emobg/web-utils';

export default {
  name: 'BookingStatusTemplate',
  props: {
    result: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    sentenceCase,
  },
};
</script>

<template>
  <div class="d-flex flex-column align-items-start">
    <ui-badge
      :color="GRAYSCALE.ground"
      class="mb-1"
    >
      <ui-icon
        :icon="ICONS.systemStatus"
        :size="ICONS_SIZES.xSmall"
        class="mr-1"
      />
      {{ sentenceCase(result.status) }}
    </ui-badge>
    <ui-badge :color="GRAYSCALE.ground">
      <ui-icon
        :icon="ICONS.vehicleStatus"
        :size="ICONS_SIZES.xSmall"
        class="mr-1"
      />
      {{ sentenceCase(result.usage_status) }}
    </ui-badge>
  </div>
</template>
