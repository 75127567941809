<script>
import map from 'lodash/map';
import { FALLBACK_MESSAGE } from '@emobg/web-utils';
import { DocumentThumbnail } from '@/components';

export default {
  components: {
    DocumentThumbnail,
  },
  props: {
    attachment: {
      type: Array,
      default: () => [],
    },
    fallback: {
      type: String,
      default: FALLBACK_MESSAGE.dash,
    },
    onClickImage: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    attachmentTransformed() {
      return map(this.attachment, item => ({ label: '', src: item }));
    },
  },
};
</script>

<template>
  <div class="AttachmentTemplate">
    <template v-if="attachment && attachment.length">
      <div
        v-for="(url, index) in attachment"
        :key="index"
        class="d-flex justify-content-start align-items-center"
      >
        <DocumentThumbnail
          :image="url"
          :on-click-image="() => onClickImage(attachmentTransformed, index)"
          class="pb-1"
        />
      </div>
    </template>
    <span v-else>{{ fallback }}</span>
  </div>
</template>
