var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.alerts.length
    ? _c(
        "section",
        _vm._l(_vm.alerts, function (alert, key) {
          return _c(
            "div",
            { key: key, attrs: { "data-test-id": `alert-${key}` } },
            [
              _c("div", {
                staticClass:
                  "d-inline-block p-2 emobg-background-color-moderate-light pill emobg-color-black align-middle",
              }),
              _c("span", { staticClass: "ml-1 align-middle" }, [
                _vm._v(_vm._s(alert)),
              ]),
            ]
          )
        }),
        0
      )
    : _c("div", [
        _c("div", {
          staticClass:
            "d-inline-block p-2 emobg-background-color-ground-light pill emobg-color-black align-middle",
        }),
        _c("span", { staticClass: "emobg-color-ink-light" }, [
          _vm._v(" No alerts "),
        ]),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }