var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.phone && !_vm.isFalsyString(_vm.phone)
      ? _c(
          "a",
          {
            staticClass: "emobg-link-primary emobg-body-2",
            attrs: { href: `tel:${_vm.phone}`, target: "_blank" },
          },
          [_vm._v(" " + _vm._s(_vm.phone) + " ")]
        )
      : _c("span", [_vm._v(" " + _vm._s(_vm.FALLBACK_MESSAGE.dash) + " ")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }