export { default as AlertsTemplate } from './Alerts/AlertsTemplate';
export { default as AttachmentTemplate } from './Attachment/AttachmentTemplate';
export { default as BookingStatusTemplate } from './BookingStatus/BookingStatusTemplate';
export { default as EmailTemplate } from './Email/EmailTemplate';
export { default as LocationTemplate } from './Location/LocationTemplate';
export { default as LogEventTemplate } from './LogEvent/LogEventTemplate';
export { default as PhoneTemplate } from './Phone/PhoneTemplate';
export { default as TableTemplate } from './Table/TableTemplate';
export { default as ListTemplate } from './ListTemplate/ListTemplate';
export { default as TernaryResultTemplate } from './TernaryResult/TernaryResultTemplate';
export { default as DeleteButtonTemplate } from './DeleteButton/DeleteButtonTemplate';
export { default as SalesOrderTemplate } from './SalesOrder/SalesOrderTemplate';

