<script>
export default {
  props: {
    labels: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
<template>
  <div class="table">
    <thead>
      <th
        v-for="label in labels"
        :key="`table-th-${label}`"
        class="p-2 emobg-font-weight-bold text-left"
      >
        {{ label }}
      </th>
    </thead>
    <tbody>
      <tr
        v-for="(item, key) in items"
        :key="`table-row-${key}`"
      >
        <td
          v-for="(cell, cellKey) in item"
          :key="`cell-${key}-${cellKey}`"
          class="px-2 py-1"
        >
          {{ cell }}
        </td>
      </tr>
    </tbody>
  </div>
</template>

