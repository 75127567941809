var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.attributes, function (attribute) {
      return _c("div", { key: attribute, staticClass: "pb-4" }, [
        _c("p", { staticClass: "emobg-font-weight-bold pb-1" }, [
          _c("strong", [
            _vm._v(_vm._s(_vm.sentenceCase(`${_vm.title} ${attribute}`)) + ":"),
          ]),
        ]),
        _vm._v(
          " " + _vm._s(_vm.getAttributeValue(_vm.section, attribute)) + " "
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }