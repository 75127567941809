var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.salesOrderUuid && !_vm.isFalsyString(_vm.salesOrderUuid)
      ? _c(
          "a",
          {
            staticClass: "emobg-link-primary emobg-body-2",
            attrs: {
              href: `/fleet-invoicing/companies/${_vm.companyUuid}/contracts/${_vm.contractUuid}/sales-orders/${_vm.salesOrderUuid}`,
            },
          },
          [_vm._v(" " + _vm._s(_vm.formatUuid(_vm.salesOrderUuid)) + " ")]
        )
      : _c("span", [_vm._v(_vm._s(_vm.FALLBACK_MESSAGE.dash))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }