<script>
import { FALLBACK_MESSAGE, isFalsyString } from '@emobg/web-utils';
import { formatUuid } from '@/domains/FleetInvoicing/utils';

export default {
  name: 'SalesOrderTemplate',
  props: {
    salesOrderUuid: {
      type: String,
      default: undefined,
    },
    companyUuid: {
      type: String,
      default: undefined,
    },
    contractUuid: {
      type: String,
      default: undefined,
    },
  },
  created() {
    this.FALLBACK_MESSAGE = FALLBACK_MESSAGE;
    this.formatUuid = formatUuid;
    this.isFalsyString = isFalsyString;
  },
};
</script>
<template>
  <div>
    <a
      v-if="salesOrderUuid && !isFalsyString(salesOrderUuid)"
      :href="`/fleet-invoicing/companies/${companyUuid}/contracts/${contractUuid}/sales-orders/${salesOrderUuid}`"
      class="emobg-link-primary emobg-body-2"
    >
      {{ formatUuid(salesOrderUuid) }}
    </a>
    <span v-else>{{ FALLBACK_MESSAGE.dash }}</span>
  </div>
</template>
