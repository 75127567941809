var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "AttachmentTemplate" },
    [
      _vm.attachment && _vm.attachment.length
        ? _vm._l(_vm.attachment, function (url, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "d-flex justify-content-start align-items-center",
              },
              [
                _c("DocumentThumbnail", {
                  staticClass: "pb-1",
                  attrs: {
                    image: url,
                    "on-click-image": () =>
                      _vm.onClickImage(_vm.attachmentTransformed, index),
                  },
                }),
              ],
              1
            )
          })
        : _c("span", [_vm._v(_vm._s(_vm.fallback))]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }