<script>
import { FALLBACK_MESSAGE, isFalsyString } from '@emobg/web-utils';

export default {
  name: 'EmailTemplate',
  props: {
    email: {
      type: String,
      default: () => undefined,
    },
    text: {
      type: String,
      default: () => undefined,
    },
  },
  created() {
    this.FALLBACK_MESSAGE = FALLBACK_MESSAGE;
  },
  methods: {
    isFalsyString,
  },
};
</script>

<template>
  <div>
    <a
      v-if="email && !isFalsyString(email)"
      :href="`mailto:${email}`"
      class="emobg-link-primary emobg-body-2"
      target="_blank"
    >
      {{ text || email }}
    </a>
    <span v-else> {{ FALLBACK_MESSAGE.dash }} </span>
  </div>
</template>
