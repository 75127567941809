var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-flex flex-column mt-n2" }, [
    _c("div", { staticClass: "emobg-font-weight-semibold pb-2" }, [
      _vm._v(" " + _vm._s(_vm.title) + " "),
    ]),
    _vm.items.length
      ? _c(
          "div",
          { staticClass: "d-flex flex-column" },
          _vm._l(_vm.items, function (item) {
            return _c("div", { key: item, staticClass: "pb-1" }, [
              _vm._v(" " + _vm._s(item) + " "),
            ])
          }),
          0
        )
      : _c("div", { staticClass: "d-flex flex-column" }, [
          _c("div", [_vm._v(" " + _vm._s(_vm.FALLBACK_MESSAGE.dash) + " ")]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }