var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "table" }, [
    _c(
      "thead",
      _vm._l(_vm.labels, function (label) {
        return _c(
          "th",
          {
            key: `table-th-${label}`,
            staticClass: "p-2 emobg-font-weight-bold text-left",
          },
          [_vm._v(" " + _vm._s(label) + " ")]
        )
      }),
      0
    ),
    _c(
      "tbody",
      _vm._l(_vm.items, function (item, key) {
        return _c(
          "tr",
          { key: `table-row-${key}` },
          _vm._l(item, function (cell, cellKey) {
            return _c(
              "td",
              { key: `cell-${key}-${cellKey}`, staticClass: "px-2 py-1" },
              [_vm._v(" " + _vm._s(cell) + " ")]
            )
          }),
          0
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }