<script>
import get from 'lodash/get';

export default {
  props: {
    result: {
      type: Object,
      required: true,
    },
    attribute: {
      type: String,
      required: true,
    },
    truthyLabel: {
      type: String,
      required: true,
    },
    falsyLabel: {
      type: String,
      required: true,
    },
    transformValue: {
      type: Function,
      default: value => value,
    },
  },
  computed: {
    text() {
      const value = get(this.result, this.attribute);
      return value ? this.transformValue(this.truthyLabel) : this.transformValue(this.falsyLabel);
    },
  },
};
</script>
<template>
  <div>
    {{ text }}
  </div>
</template>
