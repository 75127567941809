<script>
export default {
  props: {
    onClick: {
      type: Function,
      default: () => {},
    },
  },
};
</script>
<template>
  <ui-button
    :color="GRAYSCALE.inkLight"
    :face="FACES.text"
    :size="SIZES.small"
    data-test-id="delete-button-template"
    class="d-block"
    @clickbutton="onClick"
  >
    <ui-icon
      :icon="ICONS.trash"
      :size="ICONS_SIZES.xLarge"
      class="mr-4"
    />
  </ui-button>
</template>
