var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex flex-column align-items-start" },
    [
      _c(
        "ui-badge",
        { staticClass: "mb-1", attrs: { color: _vm.GRAYSCALE.ground } },
        [
          _c("ui-icon", {
            staticClass: "mr-1",
            attrs: {
              icon: _vm.ICONS.systemStatus,
              size: _vm.ICONS_SIZES.xSmall,
            },
          }),
          _vm._v(" " + _vm._s(_vm.sentenceCase(_vm.result.status)) + " "),
        ],
        1
      ),
      _c(
        "ui-badge",
        { attrs: { color: _vm.GRAYSCALE.ground } },
        [
          _c("ui-icon", {
            staticClass: "mr-1",
            attrs: {
              icon: _vm.ICONS.vehicleStatus,
              size: _vm.ICONS_SIZES.xSmall,
            },
          }),
          _vm._v(" " + _vm._s(_vm.sentenceCase(_vm.result.usage_status)) + " "),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }